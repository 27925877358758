import React from "react";
import classes from "./FertilityClinic.module.css";
import ConsultationForm from "./ConsultationForm/ConsultationForm";
import HowWeWork from "./HowWeWork/HowWeWork";
import TeamOfExperts from "./TeamOfExperts/TeamOfExperts";
import OurClinic from "./OurClinic/OurClinic";
import GoogleReviews from "./GoogleReviews/GoogleReviews";
import PrinciplesSection from "./PrinciplesSection/PrinciplesSection";
import FeaturedIn from "./FeaturedIn/FeaturedIn";

const HeroDiv = () => (
  <div className={classes.heroDiv}>
    <div className={classes.textContainer}>
      <h1 className={`fc_heroHeading ${classes.heroHeading}`}>
        Fertility is beyond IVF
      </h1>
      <hr className={classes.hrDivider} />
      <p className={`fc_caption`}>
        Understand all your options, discuss your doubts and take a confident
        decision. Guidance is ours, decision is yours.
      </p>
    </div>
    <img
      className={classes.heroImg}
      src="https://cdn.imumz.com/pages.imumz/q2hMoj0Rc9Kgj1ZH8f7qxOt25g.png.png"
      alt="hero-images"
    />
  </div>
);

const FertilityClinic = () => {
  return (
    <div className={classes.outerDiv}>
      <div className={classes.container}>
        <HeroDiv />
        <ConsultationForm/>
        <HowWeWork/>
        <TeamOfExperts/>
        <OurClinic/>
        {/* <GoogleReviews/> */}
        <PrinciplesSection/>
        <FeaturedIn/>
      </div>
    </div>
  );
};

export default FertilityClinic;

{
  /* <h1 className={classes.heroHeading}>Fertility is beyond IVF</h1>
            <h1 className={classes.pageHeading}>How we work</h1>
            <h1 className={classes.priceHeading}>₹999</h1>
            <h1 className={classes.blogTitle}>How we work</h1>
            <p className={classes.caption}>Understand all your options, discuss your doubts and take a confident decision. Guidance is ours, decision is yours.</p>
            <p  className={classes.boldCaption} >Not satisfied? Get Complete Refund - No questions asked!</p> */
}
