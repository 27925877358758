

export const QUERY_PARAM_DIRECTORY = {
    whatsAppNumber : "whatsAppNumber",
    doctor: 'doctor',
    location: 'location',
    doctor_experience: 'doctor_experience',
    consultationTime: 'consultation_time',
    mrp : "mrp",
    discountedPrice : "discounted_price"
}

export const ROUTE_ID_DIRECTORY = {
    fertilityClinic: "fertility_clinic_rid",
    summary : "summary_rid",
    appointment: "appointment_rid",
    successPage  : "success_page_rid",
}