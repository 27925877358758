function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const handlePayment = async (
  paymentDetails,
  userData,
  notes,
  onPaymentDone,
  onCanceled,
  description
) => {
  const { order, amount } = paymentDetails;
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!order || !amount) {
    return;
  }

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const options = {
    key: "rzp_live_w84zhbNIRU2ayl", // Enter the Key ID generated from the Dashboard
    amount: amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "iMumz",
    description: "website Transaction : " + description||"",
    image:
      "https://storage.googleapis.com/download/storage/v1/b/pruoo-app.appspot.com/o/%2FO2ThvonZtOml1qWey2Ds7259418-96db5af5bb1674d854e6f970d9c73f76-medium_jpg.webp?generation=1676478569392864&alt=media",
    order_id: order, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      console.log(JSON.stringify(response));
      onPaymentDone(response);
    },
    prefill: {
      name: userData.name,
      email: userData.email,
      contact: userData.whatsAppNumber,
    },
    notes: notes,
    theme: {
      color: "#80C7BC",
    },
  };
  const rzp1 = new window.Razorpay(options);

  rzp1.on("payment.failed", function (response) {
    console.log(JSON.stringify(response));
    alert(response.error.description);
    onCanceled(response);
  });
  rzp1.open();
};
