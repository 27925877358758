import React from "react";
import styles from "./BookingConfirmation.module.css";
import { FaCheck, FaTimes, FaArrowLeft } from "react-icons/fa";
import { goBack } from "../../utils/ui-utils";


const BookingConfirmation = ({
  success = true,
  successPrompt = (
    <>
      Success! Your <span className={styles.highlight}>FREE CONSULTATION</span> has been
      booked with our expert!
    </>
  ),
  errorPrompt = (
    <>
      Oops! There was an issue with your <span className={styles.highlight}>CONSULTATION</span>. Please try again.
    </>
  ),
  customPrompt, // Optional custom message override
  onBackClick = goBack, // Function to handle back button click
}) => {
  const prompt = customPrompt || (success ? successPrompt : errorPrompt);
  const iconColor = success ? "#7d4ede" : "#f44336"; // Purple for success, Red for error
  const iconBgColor = success ? "#e0d7f5" : "#f8d7da"; // Light purple for success, Light red for error
  const highlightColor = success ? "#7d4ede" : "#f44336"; // Highlight color based on success
  const IconComponent = success ? FaCheck : FaTimes;

  return (
    <div className={styles.successContainer}>
      {/* Header with back button in failure state */}
      {!success && (
        <button onClick={onBackClick} className={styles.backButton}>
          <FaArrowLeft className={styles.backIcon} /> Back{/* Back Icon */}
        </button>
      )}

      <h1 className={styles.successMessage}>
        {React.cloneElement(prompt, {
          children: React.Children.map(prompt.props.children, (child) =>
            child?.type === "span"
              ? React.cloneElement(child, {
                  style: { color: highlightColor }, // Change color dynamically
                })
              : child
          ),
        })}
      </h1>

      <div
        className={styles.iconContainer}
        style={{ backgroundColor: iconBgColor }}
      >
        <div
          className={styles.innerCircle}
          style={{ backgroundColor: iconColor }}
        >
          <IconComponent className={styles.checkIcon} />
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
