import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HourglassComponent from "./components/UI/HourglassComponent/HourglassComponent";
import {
  ROUTE_BOOKING_CONFIRMATION,
  ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2,
  ROUTE_EVENT_BOOKING_V2,
  ROUTE_FERTILITY_CLINIC,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS,
  ROUTE_PREGNANCY_SLOT_BOOKING,
  ROUTE_REGISTRATION_SUCCESSFUL_PAGE,
  ROUTE_THANK_YOU_FORM,
  ROUTE_TS_GTM_REGISTRATION,
} from "./utils/constants/global-constants";
import FertilityClinicPage from "./pages/FertilityClinicPage";
import FertilityClinicAppointmentsPage from "./pages/FertilityClinicAppointmentsPage";
import PregnancySlotBookingPage from "./pages/PregnancySlotBookingPage";
import BookingConfirmationPage from "./pages/BookingConfirmationPage";
import FertilityClinicAppointmentSuccessPage from "./pages/FertilityClinicAppointmentSuccessPage";
import FertilityClinicAppointmentsSummaryPage from "./pages/FertilityClinicAppointmentsSummaryPage";
import { ROUTE_ID_DIRECTORY } from "./utils/constants/fertility-clinic-constants";
const ThankyouFormPage = lazy(() => import("./pages/ThankyouFormPage"));
const RegistrationSuccessPage = lazy(() =>
  import("./pages/registrationSuccessPage")
);
const DummyPage = lazy(() => import("./pages/dummyPage"));
const WcpRenewalPage = lazy(() => import("./pages/wcpRenewalPage"));
const SppRenewalPage = lazy(() => import("./pages/SppRenewalPage"));
const PcpRenewalPage = lazy(() => import("./pages/PcpRenewalPage"));
const ReddyParentingUserForm = lazy(() =>
  import("./pages/ReddyParentingUserForm")
);
const UserForm = lazy(() => import("./pages/WcpUserForm"));
const WcpPlans = lazy(() => import("./pages/WcpPlans"));
const StandardPlans = lazy(() => import("./pages/StandardPlans"));
const RootLayout = lazy(() => import("./pages/Root"));
const PaymentSuccessFeedBackPage = lazy(() =>
  import("./pages/PaymentSuccessFeedBackPage")
);
const SlotBookingPage = lazy(() => import("./pages/SlotBooking"));
const SlotBookingFeedBackPage = lazy(() =>
  import("./pages/SlotBookingFeedBackPage")
);
const Workshop = lazy(() => import("./pages/Workshop"));
const SpUserForm = lazy(() => import("./pages/SpUserForm"));
const SuperParentingForm = lazy(() => import("./pages/SuperParentingForm"));
const PrescriptionPage = lazy(() => import("./pages/PrescriptionPage"));
const CalendlyReportPage = lazy(() => import("./pages/CalendlyReportPage"));
const EventBookingPage = lazy(() => import("./pages/EventBookingPage"));
const EventBookingPageV2 = lazy(() => import("./pages/EventBookingPageV2"));
const EventBookingFeedbackPage = lazy(() =>
  import("./pages/EventBookingFeedbackPage")
);
const EventBookingFeedbackPageV2 = lazy(() =>
  import("./pages/EventBookingFeedbackPageV2")
);
const FertilityBookingPage = lazy(() => import("./pages/FertilityBookingPage"));
const ReddyPregnancyUserForm = lazy(() =>
  import("./pages/ReddyPregnancyUserForm")
);

function App() {
  const LoadingElement = <HourglassComponent />;
  //  (
  //   <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
  // );

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Suspense fallback={LoadingElement}>
          <RootLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={LoadingElement}>
              <UserForm />
            </Suspense>
          ),
        },
        {
          path: "superParenting",
          loader: () =>
            import("./pages/SuperParentingForm").then((module) =>
              module.loader()
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <SuperParentingForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "wcpRenewal",
          loader: (meta) =>
            import("./pages/wcpRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <WcpRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "sppRenewal",
          loader: (meta) =>
            import("./pages/SppRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <SppRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "pcpRenewal",
          loader: (meta) =>
            import("./pages/PcpRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <PcpRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "drReddyPregnancy",
          element: (
            <Suspense fallback={LoadingElement}>
              <ReddyPregnancyUserForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "drReddyParenting",
          element: (
            <Suspense fallback={LoadingElement}>
              <ReddyParentingUserForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "standard",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={LoadingElement}>
                  <SpUserForm />
                </Suspense>
              ),
            },
            {
              path: "plans",
              id: "std-program-plans",
              loader: (meta) =>
                import("./pages/StandardPlans").then((module) =>
                  module.loader(meta)
                ),
              element: (
                <Suspense fallback={LoadingElement}>
                  <StandardPlans />
                </Suspense>
              ),
              query: {
                lmp: "2023-07-19",
              },
            },
          ],
        },
        {
          path: "plans",
          id: "program-plans",
          loader: (meta) =>
            import("./pages/WcpPlans").then((module) => module.loader(meta)),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={LoadingElement}>
                  <WcpPlans />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "workshop",
          element: <Workshop />,
          loader: () =>
            import("./pages/Workshop").then((module) => module.loader()),
        },
        {
          path: "success-feedback",
          element: (
            <Suspense fallback={LoadingElement}>
              <PaymentSuccessFeedBackPage />
            </Suspense>
          ),
        },
        {
          path: "slot-booking",
          element: (
            <Suspense fallback={LoadingElement}>
              <SlotBookingPage />
            </Suspense>
          ),
          loader: () =>
            import("./pages/SlotBooking").then((module) => module.loader()),
        },
        {
          path: "SlotBookingFeedBackPage",
          element: (
            <Suspense fallback={LoadingElement}>
              <SlotBookingFeedBackPage />
            </Suspense>
          ),
        },
        {
          path: "prescription",
          element: (
            <Suspense fallback={LoadingElement}>
              <PrescriptionPage />
            </Suspense>
          ),
          loader: () =>
            import("./pages/PrescriptionPage").then((module) =>
              module.loader()
            ),
        },
        {
          path: "calendlyReport",
          element: (
            <Suspense fallback={LoadingElement}>
              <CalendlyReportPage />
            </Suspense>
          ),
          action: ({ request, params }) =>
            import("./pages/CalendlyReportPage").then((module) =>
              module.action({ request, params })
            ),
        },
        {
          path: "eventBooking",
          children: [
            {
              index: true,
              element: <EventBookingPage />,
              loader: (meta) =>
                import("./pages/EventBookingPage").then((module) =>
                  module.loader(meta)
                ),
            },
          ],
        },
        {
          path: "eventBookingFeedback",
          element: (
            <Suspense fallback={LoadingElement}>
              <EventBookingFeedbackPage />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/EventBookingFeedbackPage").then((module) =>
              module.loader(meta)
            ),
        },
        {
          path: ROUTE_EVENT_BOOKING_V2,
          children: [
            {
              index: true,
              element: <EventBookingPageV2 />,
              loader: (meta) =>
                import("./pages/EventBookingPageV2").then((module) =>
                  module.loader(meta)
                ),
            },
          ],
        },
        {
          path: ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2,
          element: (
            <Suspense fallback={LoadingElement}>
              <EventBookingFeedbackPageV2 />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/EventBookingFeedbackPageV2").then((module) =>
              module.loader(meta)
            ),
        },
        {
          path: "fertilityBooking",
          element: (
            <Suspense fallback={LoadingElement}>
              <FertilityBookingPage />
            </Suspense>
          ),
          action: ({ request, params }) =>
            import("./pages/FertilityBookingPage").then((module) =>
              module.action({ request, params })
            ),
        },
        {
          path: ROUTE_TS_GTM_REGISTRATION,
          element: (
            <Suspense fallback={LoadingElement}>
              <DummyPage />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/dummyPage").then((module) => module.loader(meta)),
        },
        {
          path: ROUTE_REGISTRATION_SUCCESSFUL_PAGE,
          element: (
            <Suspense fallback={LoadingElement}>
              <RegistrationSuccessPage />
            </Suspense>
          ),
        },
        {
          path: ROUTE_THANK_YOU_FORM,
          element: (
            <Suspense fallback={LoadingElement}>
              <ThankyouFormPage />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/dummyPage").then((module) => module.loader(meta)),
        },
        // {
        //   path: ROUTE_FERTILITY_CLINIC,
        //   element: (
        //     <Suspense fallback={LoadingElement}>
        //       <FertilityClinicPage/>,
        //     </Suspense>
        //   ),
        // },
        // {
        //   path: ROUTE_FERTILITY_CLINIC_APPOINTMENTS,
        //   element: (
        //     <Suspense fallback={LoadingElement}>
        //       <FertilityClinicAppointmentsPage/>,
        //     </Suspense>
        //   ),
        // },
        // {
        //   path: ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE,
        //   element: (
        //     <Suspense fallback={LoadingElement}>
        //       <FertilityClinicAppointmentsSummaryPage/>,
        //     </Suspense>
        //   ),
        // },
        // {
        //   path: ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS,
        //   element: (
        //     <Suspense fallback={LoadingElement}>
        //       <FertilityClinicAppointmentSuccessPage/>,
        //     </Suspense>
        //   ),
        // },
        {
          path: ROUTE_FERTILITY_CLINIC,
          children: [
            {
              index: true,
              id : ROUTE_ID_DIRECTORY.fertilityClinic,
              element: (
                <Suspense fallback={LoadingElement}>
                  <FertilityClinicPage />
                </Suspense>
              ),
            },
            {
              path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS,
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={LoadingElement}>
                      <FertilityClinicAppointmentsPage />
                    </Suspense>
                  ),
                },
                {
                  path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE,
                  children: [
                    {
                      index: true,
                      element: (
                        <Suspense fallback={LoadingElement}>
                          <FertilityClinicAppointmentsSummaryPage />
                        </Suspense>
                      ),
                    },
                    {
                      path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS,
                      id : ROUTE_ID_DIRECTORY.successPage,
                      element: (
                        <Suspense fallback={LoadingElement}>
                          <FertilityClinicAppointmentSuccessPage />
                        </Suspense>
                      ),
                      loader: (meta) =>
                        import("./pages/FertilityClinicAppointmentSuccessPage").then((module) =>
                          module.loader(meta)
                        ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ROUTE_PREGNANCY_SLOT_BOOKING,
          element: (
            <Suspense fallback={LoadingElement}>
              <PregnancySlotBookingPage />
            </Suspense>
          ),
        },
        {
          path: ROUTE_BOOKING_CONFIRMATION,
          element: (
            <Suspense fallback={LoadingElement}>
              <BookingConfirmationPage />
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/BookingConfirmationPage").then((module) =>
              module.loader(meta)
            ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
