import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaCalendarAlt } from "react-icons/fa";
import { format, addDays, setHours, setMinutes, isAfter, addHours } from "date-fns";
import styles from "./Consultation.module.css";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router";
import { CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE } from "../../../utils/constants/global-constants";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAM_DIRECTORY } from "../../../utils/constants/fertility-clinic-constants";
import { goBack } from "../../../utils/ui-utils";




// Generate dynamic slots for consultation

function generateConsultationData(daysCount = 5, config = {}) {
  // Default configuration
  const defaultConfig = {
    includeDays: [], // Days to include (0: Sunday, 1: Monday, ..., 6: Saturday)
    excludeDays: [], // Days to exclude (0: Sunday, 1: Monday, ..., 6: Saturday)
    offDates: [], // Specific dates to exclude (e.g., ["2024-11-20", "2024-11-25"])
    timeRange: { from: "10:00", till: "20:00" }, // Time range (HH:mm)
  };

  const mergedConfig = { ...defaultConfig, ...config };

  const parseTime = (timeString) => {
    const [hour, minute] = timeString.split(":").map(Number);
    return { hour, minute };
  };

  const { from, till } = mergedConfig.timeRange;
  const fromTime = parseTime(from);
  const tillTime = parseTime(till);

  // Generate time slots dynamically based on the given range
  const timeSlots = [];
  let currentTime = { ...fromTime };

  while (
    currentTime.hour < tillTime.hour ||
    (currentTime.hour === tillTime.hour && currentTime.minute <= tillTime.minute)
  ) {
    timeSlots.push({ hour: currentTime.hour, minute: currentTime.minute });

    // Increment by 30 minutes
    currentTime.minute += 30;
    if (currentTime.minute >= 60) {
      currentTime.minute = 0;
      currentTime.hour += 1;
    }
  }

  const consultationData = { availableSlots: [], slots: {} };
  const twoHoursLater = addHours(new Date(), 2);

  let currentDayIndex = 0;
  while (consultationData.availableSlots.length < daysCount) {
    const date = addDays(new Date(), currentDayIndex);
    const dayOfWeek = date.getDay(); // Get the day of the week (0-6)
    const dateISO = format(date, "yyyy-MM-dd");

    // Check if the day should be excluded based on day of the week or specific off dates
    if (
      mergedConfig.offDates.includes(dateISO) || // Skip specific dates
      mergedConfig.excludeDays.includes(dayOfWeek) || // Skip excluded days
      (mergedConfig.includeDays.length > 0 &&
        !mergedConfig.includeDays.includes(dayOfWeek)) // Skip days not in `includeDays`
    ) {
      currentDayIndex += 1;
      continue; // Skip this day
    }

    const slotsForDay = timeSlots
      .map(({ hour, minute }) => setHours(setMinutes(date, minute), hour))
      .filter((time) => currentDayIndex !== 0 || isAfter(time, twoHoursLater))
      .map((time) => time.toISOString());

    consultationData.slots[dateISO] = slotsForDay;
    consultationData.availableSlots.push({
      date: dateISO,
      slots: slotsForDay.length,
    });

    currentDayIndex += 1;
  }

  return consultationData;
}

// Categorize slots into morning, afternoon, and evening
function categorizeSlotsByTimeOfDay(slots) {
  const morningSlots = [];
  const afternoonSlots = [];
  const eveningSlots = [];

  if (slots) {
    slots.forEach((slot) => {
      const hour = new Date(slot).getHours();
      if (hour < 12) morningSlots.push(slot);
      else if (hour >= 12 && hour < 17) afternoonSlots.push(slot);
      else eveningSlots.push(slot);
    });
  }

  return {
    morning: morningSlots,
    afternoon: afternoonSlots,
    evening: eveningSlots,
  };
}

const consultationData = generateConsultationData(5,{
  includeDays: [], // Days to include (0: Sunday, 1: Monday, ..., 6: Saturday)
  excludeDays: [0,6], // Days to exclude (0: Sunday, 1: Monday, ..., 6: Saturday)
  offDates: [], // Specific dates to exclude (e.g., ["2024-11-20", "2024-11-25"])
  timeRange: { from: "10:00", till: "20:00" }, // Time range (HH:mm)
}
);

const Consultation = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const doctorData = {
    name: "Dr. Sunil Eshwar",
    experience: "10 yrs exp • MBBS, MD - Obstetrics & Gynaecology",
    location: "JP Nagar, Bangalore",
    timeRange: "10 AM - 8 PM",
  };

  // Set default date on component mount
  useEffect(() => {
    if (consultationData.availableSlots.length > 0) {
      setSelectedDate(consultationData.availableSlots[0].date);
    }
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Reset time when changing date
  };

  const handleTimeSelect = (time) => setSelectedTime(time);

  const handleNextClick = () => {
    if (selectedDate && selectedTime) {
      const consultationTime = selectedTime;
      const bookingDetails = {
        doctorDetails: {
          name: doctorData.name,
          experience: doctorData.experience,
          location: doctorData.location,
          timeRange: doctorData.timeRange,
        },
        consultationTime,
        displayDate: format(new Date(selectedDate), "dd/MM/yyyy"),
        displayTime: format(new Date(selectedTime), "hh:mm a"),
      };
      searchParams.set(QUERY_PARAM_DIRECTORY.doctor, bookingDetails.doctorDetails.name);
      searchParams.set(QUERY_PARAM_DIRECTORY.location, bookingDetails.doctorDetails.location);
      searchParams.set(QUERY_PARAM_DIRECTORY.doctor_experience, bookingDetails.doctorDetails.experience);
      searchParams.set(QUERY_PARAM_DIRECTORY.consultationTime, bookingDetails.consultationTime);

      console.log("Booking Details:", bookingDetails);
      navigate(`${CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE}?${searchParams.toString()}`);
    }
  };
  
  const slotsForSelectedDate = selectedDate
    ? consultationData.slots[selectedDate]
    : [];
  const categorizedSlots = categorizeSlotsByTimeOfDay(slotsForSelectedDate);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={goBack}>
            <FaArrowLeft className={styles.backIcon} /> Back
          </button>
        </div>

        <div className={styles.doctorInfo}>
          <p className={styles.timeRange}>{doctorData.timeRange}</p>
          <div className={styles.doctorDetails}>
            <img
              src="https://cdn.imumz.com/pages.imumz/drSunil.png"
              alt="Doctor"
              className={styles.doctorImage}
            />
            <div className={styles.doctorTextDetails}>
              <p className={styles.doctorName}>{doctorData.name}</p>
              <p className={styles.doctorDescription}>{doctorData.experience}</p>
              <p className={styles.doctorLocation}>{doctorData.location}</p>
            </div>
          </div>
        </div>

        <div className={styles.slotSelection}>
          <h2 className={styles.sectionTitle}>
            <FaCalendarAlt className={styles.icon} /> Select slots
          </h2>
          <div className={styles.dateContainer}>
            {consultationData.availableSlots.map((slot, index) => (
              <div
                key={index}
                className={`${styles.date} ${
                  slot.date === selectedDate ? styles.selected : ""
                }`}
                onClick={() => handleDateSelect(slot.date)}
              >
                <p className={styles.dateText}>
                  {format(new Date(slot.date), "EEE")}
                </p>
                <p className={styles.dateNumber}>
                  {format(new Date(slot.date), "dd")}
                </p>
                <p className={styles.slotsAvailable}>
                  {slot.slots} Slot{slot.slots > 1 && "s"}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.notice}>
            <IoIosInformationCircleOutline /> <p>Slots open 5 days earlier</p>
          </div>
        </div>

        {categorizedSlots.morning.length > 0 && (
          <TimeSection
            title="Morning"
            slots={categorizedSlots.morning}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.afternoon.length > 0 && (
          <TimeSection
            title="Afternoon"
            slots={categorizedSlots.afternoon}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.evening.length > 0 && (
          <TimeSection
            title="Evening"
            slots={categorizedSlots.evening}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.morning.length === 0 &&
          categorizedSlots.afternoon.length === 0 &&
          categorizedSlots.evening.length === 0 && (
            <div className={styles.caption}>
              <p>No slots available for selected date.</p>
            </div>
          )}
      </div>

      <div className={styles.footer}>
        <button
          className={styles.nextButton}
          onClick={handleNextClick}
          disabled={!selectedDate || !selectedTime} // Disable button if no slot is selected
        >
          Next
        </button>
      </div>
    </div>
  );
};

const TimeSection = ({ title, slots, selectedTime, handleTimeSelect }) => (
  <div className={styles.timeSlotsContainer}>
    <h3 className={styles.timeSlotTitle}>{title}</h3>
    <div className={styles.timeSlots}>
      {slots.map((time, index) => (
        <button
          key={index}
          className={`${styles.timeSlot} ${
            time === selectedTime ? styles.selected : ""
          }`}
          onClick={() => handleTimeSelect(time)}
        >
          {format(new Date(time), "hh:mm a")}
        </button>
      ))}
    </div>
  </div>
);

export default Consultation;
