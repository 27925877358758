import React, { useState } from "react";
import styles from "./ConsultationForm.module.css";
import { FaWhatsapp } from "react-icons/fa"; // Importing WhatsApp icon from react-icons
import OfflineConsultation from "./OfflineConsultation";
import BookingSection from "./BookingSection";
import Spacer from "../util/Spacer";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS } from "../../../utils/constants/global-constants";
import { QUERY_PARAM_DIRECTORY } from "../../../utils/constants/fertility-clinic-constants";

const ConsultationForm = () => {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const MRP = 1999
  const DISCOUNTED_PRICE = 999

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only digits and clear error on valid input
    if (/^\d*$/.test(value)) {
      setWhatsappNumber(value);

      // Clear error if the length is valid
      if (value.length === 10) {
        setErrorMessage("");
      }
    }
  };

  const onBooking = (event) => {
    if (whatsappNumber.length !== 10) {
      // Set error and prevent navigation
      setErrorMessage("Invalid number. Please enter a 10-digit number.");
      return;
    }

    // If valid, save number and navigate
    searchParams.set(QUERY_PARAM_DIRECTORY.whatsAppNumber, whatsappNumber);
    searchParams.set(QUERY_PARAM_DIRECTORY.mrp, MRP);
    searchParams.set(QUERY_PARAM_DIRECTORY.discountedPrice, DISCOUNTED_PRICE);
    navigate( CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS + "?" + searchParams.toString()
    );
  };

  return (
    <>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>
        Consult iMumz fertility experts at{" "}
          <span className={styles.price}>₹{DISCOUNTED_PRICE}</span>
        </h2>
        <p className={styles.subText}>
          Helped <span className={styles.highlight}>1,000+</span>{" "}
          couples conceive naturally
        </p>
        <label className={styles.label}>
          Enter WhatsApp number to select slot
        </label>

        <div
          className={`${styles.inputContainer} ${
            errorMessage ? styles.errorInputContainer : ""
          }`}
        >
          <span className={styles.prefix}>+91</span>
          <div className={styles.divider}></div>{" "}
          {/* Divider between country code and icon */}
          <FaWhatsapp className={styles.icon} />
          <input
            type="tel"
            id="tel"
            className={styles.inputField}
            placeholder="WhatsApp Number*"
            value={whatsappNumber}
            onChange={handleChange}
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <OfflineConsultation />
      </div>
      <Spacer height={12} />
      <BookingSection onClick={onBooking} price={DISCOUNTED_PRICE} />{" "}
      {/* we have to use onclick onsubmit */}
      <Spacer height={24} />
      <hr className={styles.hrLine} />
    </>
  );
};

export default ConsultationForm;
