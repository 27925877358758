import React from "react";
import styles from "./OurClinic.module.css";
import { FaMapMarkerAlt, FaEye, FaWhatsapp } from "react-icons/fa";

const OurClinic = () => {
  // Function to scroll to the top of the screen
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll animation
    });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Our Clinic</h2>

      {/* Location Tag */}
      <span className={styles.locationTag}>JP Nagar, Bengaluru</span>

      {/* Clinic Image */}
      <img
        src="https://cdn.imumz.com/pages.imumz/clinic.png"
        alt="Clinic"
        className={styles.clinicImage}
      />

      {/* Map Link and Address */}
      <div className={styles.mapLink}>
        <FaMapMarkerAlt className={styles.icon} />
        <a
          href="https://www.google.com/maps/place/iMumz+Fertility+Centre/@12.9110324,77.5764458,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15fcc281d5f5:0x253fc4282ed5e8c8!8m2!3d12.9110324!4d77.5790207!16s%2Fg%2F11wmw7jsp8?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"
          className={styles.mapText}
        >
          {"View on Map >"}
        </a>
      </div>
      <p className={styles.address}>
        iMumz Fertility Clinic, 4th Floor, 166, 9th Cross Rd, near Indira Gandhi
        Circle, ITI Layout, 1st Phase, J. P. Nagar, Bengaluru, Karnataka 560078
      </p>

      {/* Hours */}
      <div className={styles.hours}>
        <FaEye className={styles.icon} />
        <strong>Hours : </strong> 10 AM to 8 PM
      </div>

      {/* Book Appointment Button */}
      <button className={styles.appointmentButton} onClick={scrollToTop}>
        <FaWhatsapp className={styles.whatsappIcon} />
        Book Appointment
      </button>
    </div>
  );
};

export default OurClinic;
