import React from 'react';
import { FaShieldAlt } from 'react-icons/fa'; // Using react-icons for the shield icon
import styles from './BookingSection.module.css';

const BookingSection = ({onClick, price}) => {
  return (
    <div className={styles.bookingContainer}>
     <div className={styles.priceDiv}>
     <div className={styles.priceSection}>
        <span className={styles.price}>₹{price}</span>
      </div>

      {/* Book Appointment Button */}
      <button onClick={onClick}  className={styles.bookButton}>Book Appointment</button>
     </div>


      {/* Refund Policy */}
      <div className={styles.refundPolicy}>
        <FaShieldAlt className={styles.refundIcon} />
        <h1 className={styles.refundText}>
          Not satisfied? Get Complete Refund – No questions asked!
        </h1>
      </div>
    </div>
  );
};

export default BookingSection;
