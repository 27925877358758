import React from "react";
import styles from "./FertilityClinicAppointmentSuccess.module.css";
import { IoLocationOutline, IoInformationCircleOutline } from "react-icons/io5";
import { MdCheckCircle, MdOutlineCalendarMonth } from "react-icons/md";
import { LuClock } from "react-icons/lu";
import { MdCurrencyRupee } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAM_DIRECTORY } from "../../utils/constants/fertility-clinic-constants";
import moment from "moment";

const FertilityClinicAppointmentSuccess = () => {
  const [searchParams] = useSearchParams()

  const appointmentData = {
    doctor: {
      name: searchParams.get(QUERY_PARAM_DIRECTORY.doctor),
      experience: searchParams.get(QUERY_PARAM_DIRECTORY.doctor_experience),
      imageUrl: "https://cdn.imumz.com/pages.imumz/drSunil.png",
    },
    clinic: {
      name: "iMumz Fertility Center",
      address:
        "4th Floor, 166, 9th Cross Rd, near Indira Gandhi Circle, ITI Layout, 1st Phase, J. P. Nagar, Bengaluru, Karnataka 560078",
    },
    appointment: {
      date: moment(searchParams.get(QUERY_PARAM_DIRECTORY.consultationTime)).format("dddd, Do MMMM, YYYY"),
      time: moment(searchParams.get(QUERY_PARAM_DIRECTORY.consultationTime)).format("hh:mm a"),
      fee: `${searchParams.get(QUERY_PARAM_DIRECTORY.discountedPrice)} payable at clinic`,
    },
    notice: "To ensure a smooth visit, please arrive 10 minutes early.",
  };

  return (
    <div className={styles.pageContainer}>
      {/* Success animation */}
      <div className={styles.successAnimation}>
        <div className={styles.greenCircle}>
          <MdCheckCircle className={styles.checkIcon} />
        </div>
      </div>

      <h2 className={styles.successMessage}>
        Consultation scheduled successfully!
      </h2>

      <div className={styles.consultationDetails}>
        {/* Doctor Information */}
        <div className={styles.doctorInfo}>
          <img
            src={appointmentData.doctor.imageUrl}
            alt={appointmentData.doctor.name}
            className={styles.doctorImage}
          />
          <div className={styles.doctorInfoTextContainer}>
            <h4 className={styles.doctorName}>{appointmentData.doctor.name}</h4>
            <p className={styles.doctorDetails}>
              {appointmentData.doctor.experience}
            </p>
          </div>
        </div>

        {/* Appointment Information */}
        <div className={styles.consultationInfo}>
          <div className={styles.infoRow}>
            <IoLocationOutline className={styles.icon} />
            <p>
              {appointmentData.clinic.name}, {appointmentData.clinic.address}
            </p>
          </div>

          <div className={styles.infoRow}>
            <MdOutlineCalendarMonth className={styles.icon} />
            <p>{appointmentData.appointment.date}</p>
          </div>

          <div className={styles.infoRow}>
            <LuClock className={styles.icon} />
            <p>{appointmentData.appointment.time}</p>
          </div>

          <div className={styles.infoRow}>
            <MdCurrencyRupee className={styles.icon} />
            <p>{appointmentData.appointment.fee}</p>
          </div>
        </div>
      </div>

      {/* Notice */}
      <div className={styles.notice}>
        <IoInformationCircleOutline className={styles.icon} />
        <p>{appointmentData.notice}</p>
      </div>
    </div>
  );
};

export default FertilityClinicAppointmentSuccess;
