import React from "react";
import styles from "./FertilityClinicAppointmentsSummary.module.css";
import { LuStethoscope } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { BiClinic } from "react-icons/bi";
import { LuCalendarDays } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineModeEdit } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { goBack } from "../../utils/ui-utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QUERY_PARAM_DIRECTORY } from "../../utils/constants/fertility-clinic-constants";
import moment from "moment";
import { ANALYTICS_KEYS_TO_REMOVE, CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS } from "../../utils/constants/global-constants";
import { removeKeysFromObject } from "../../utils/analytic-utils";
import { useDispatch } from "react-redux";
import { sendFbEventWithoutUserDataState } from "../../store/custom-actions-creator";
// Sample data simulating an API response
function createPaymentSummary(mrp, discountedPrice) {
  const totalSavings = mrp - discountedPrice;

  return {
    items: [
      {
        name: "MRP",
        price: "₹"+mrp,
        hr: false,
      },
      {
        name: "iMumz discount",
        discount: `- ₹${totalSavings}`,
        hr: true,
      },
      {
        name: "Discounted Price",
        price: "₹"+discountedPrice,
        hr: true,
      },
    ],
    total: discountedPrice,
    totalSavings: totalSavings,
  };
}

const SummaryPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const summaryData = {
    whatsAppNumber : searchParams.get(QUERY_PARAM_DIRECTORY.whatsAppNumber),
    consultationMedium: "Visiting iMumz Fertility Centre",
    location: searchParams.get(QUERY_PARAM_DIRECTORY.location),
    doctor: {
      name: searchParams.get(QUERY_PARAM_DIRECTORY.doctor),
      specialization: "Psychiatry",
      experience: searchParams.get(QUERY_PARAM_DIRECTORY.doctor_experience),
    },
    consultationTime: {
      date: moment(
        searchParams.get(QUERY_PARAM_DIRECTORY.consultationTime)
      ).format("DD-MM-YYYY"),
      time: moment(
        searchParams.get(QUERY_PARAM_DIRECTORY.consultationTime)
      ).format("hh:mm a"),
    },
    paymentSummary: createPaymentSummary(
      searchParams.get(QUERY_PARAM_DIRECTORY.mrp),
      searchParams.get(QUERY_PARAM_DIRECTORY.discountedPrice)
    ),
    paymentInfo: `₹${searchParams.get(
      QUERY_PARAM_DIRECTORY.discountedPrice
    )} payable at clinic`,
    refundPolicy:
      "If you're not fully satisfied, we'll give you a 100% refund.",
  };
  const onNextHandler = () => {
    window.fbq(
      "track",
      "Lead",
      removeKeysFromObject(summaryData, ANALYTICS_KEYS_TO_REMOVE)
    );
    window.dataLayer.push(
      removeKeysFromObject(summaryData, ANALYTICS_KEYS_TO_REMOVE)
    );
    dispatch(sendFbEventWithoutUserDataState("jpl", {phone : summaryData.whatsAppNumber}))
    navigate(
      `${CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS}?${searchParams.toString()}`
    );
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={goBack}>
            <FaArrowLeft className={styles.backIcon} /> Back
          </button>
          {/* <h1 className={styles.title}>Schedule Consultation</h1> */}
        </div>

        {/* Consultation Medium Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Consultation medium</span>
            {/* <MdOutlineModeEdit className={styles.editIcon} /> */}{" "}
            {/* edit option */}
          </div>
          <div className={styles.sectionContent}>
            <span>
              <BiClinic className={styles.icon} />
              {summaryData.consultationMedium}
            </span>
          </div>
        </div>

        <hr className={styles.hrTag} />
        {/* Clinic & Doctor Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Clinic & Doctor</span>
            {/* <MdOutlineModeEdit className={styles.editIcon} /> */}{" "}
            {/* edit option */}
          </div>
          <div className={styles.sectionContent}>
            <span>
              <IoLocationOutline className={styles.icon} />
              {summaryData.location}
            </span>

            <span>
              <LuStethoscope className={styles.icon} />
              {summaryData.doctor.name}
            </span>
          </div>
        </div>

        <hr className={styles.hrTag} />
        {/* Time & Date Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Time & date</span>
            <MdOutlineModeEdit className={styles.editIcon} onClick={goBack} />
          </div>
          <div className={styles.sectionContent}>
            <span>
              <LuCalendarDays className={styles.icon} />
              {summaryData.consultationTime.date}
            </span>
            <span>
              <FaRegClock
                className={styles.icon}
                key={summaryData.consultationTime.time}
              />
              {summaryData.consultationTime.time}
            </span>
          </div>
        </div>
        <hr className={styles.hrTag} key={summaryData.consultationTime.time} />
        {/* Payment Summary Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Payment summary</div>
          {summaryData.paymentSummary.items.map((item, index) => (
            <>
              <div className={styles.paymentItem} key={index}>
                <span>{item.name}</span>
                <span>
                  {item.originalPrice && (
                    <span className={styles.strikethrough}>
                      ₹{item.originalPrice}
                    </span>
                  )}
                  {item.discountedPrice || item.price || item.discount}
                </span>
              </div>
              {item.hr && <hr className={styles.dashedHr} />}
            </>
          ))}
          <div className={`${styles.paymentItem} ${styles.total}`}>
            <span>Total</span>
            <span>₹{summaryData.paymentSummary.total}</span>
          </div>
        </div>

        {/* Total Savings Notice */}
        <div className={styles.savingsNotice}>
          <span>
            <BiSolidOffer className={styles.icon} />
            Total savings ₹{summaryData.paymentSummary.totalSavings}
          </span>
        </div>

        {/* Refund Policy Section */}
        <div className={styles.refundPolicy}>
          <div className={styles.refundPolicyTextContainer}>
            <h4>Refund policy</h4>
            <p>{summaryData.refundPolicy}</p>
          </div>
          <img
            className={styles.returnPolicyImg}
            src="https://cdn.imumz.com/pages.imumz/return-policity-fc.png"
            alt="return"
          />
        </div>
      </div>
      {/* Bottom Bar with Payment Information and Next Button */}
      <div className={styles.bottomBar}>
        <div className={styles.paymentInfo}>
          <BiSolidOffer className={styles.icon} />
          ₹{ searchParams.get(QUERY_PARAM_DIRECTORY.discountedPrice)} payable at clinic
        </div>
        <button className={styles.nextButton} onClick={onNextHandler}>
          Next
        </button>
      </div>
    </div>
  );
};

const FertilityClinicAppointmentsSummary = () => {
  return (
    <div className={styles.outerDiv}>
      <div className={styles.container}>
        <SummaryPage />
      </div>
    </div>
  );
};

export default FertilityClinicAppointmentsSummary;
