import React, { Suspense } from "react";
import BookingConfirmation from "../components/BookingConfirmation/BookingConfirmation";
import { defer, Await, useRouteLoaderData } from "react-router";
import FertilityClinicAppointmentSuccess from '../components/FertilityClinicAppointmentSuccess/FertilityClinicAppointmentSuccess'
import { QUERY_PARAM_DIRECTORY, ROUTE_ID_DIRECTORY } from '../utils/constants/fertility-clinic-constants'
import { reqAppointmentBookingRetool } from '../lib/api'
import HourglassComponent from "../components/UI/HourglassComponent/HourglassComponent";

const FertilityClinicAppointmentSuccessPage = () => {
  const { events } = useRouteLoaderData(ROUTE_ID_DIRECTORY.successPage);
  return (
    <Suspense fallback={<HourglassComponent />}>
      <Await resolve={events} errorElement={ <BookingConfirmation success={false} /> }>
        {(_loadedEvents) => <FertilityClinicAppointmentSuccess />}
      </Await>
    </Suspense>
  );
}

export default FertilityClinicAppointmentSuccessPage


export const loader = async ({ request }) => {
  const url = new URL(request.url);
  const whatsAppNumber = url.searchParams.get(QUERY_PARAM_DIRECTORY.whatsAppNumber);
  const doctor = url.searchParams.get(QUERY_PARAM_DIRECTORY.doctor);
  const consultationTime = url.searchParams.get(QUERY_PARAM_DIRECTORY.consultationTime);
  const discounted_price = url.searchParams.get(QUERY_PARAM_DIRECTORY.discountedPrice);
  const queryParams = Object.fromEntries(url.searchParams.entries());

    return defer({
      events: reqAppointmentBookingRetool({whatsAppNumber, doctor, consultationTime,discounted_price, extras : queryParams}),
    });
  };
  